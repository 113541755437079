

/// AJAX
function submitForm(){

    let xhr = new XMLHttpRequest(),
        url = directory_uri + '/php/mail.php',
        params = 'chaine=Nouvelle demande de : '+ encodeURIComponent(document.getElementById('name').value) + '\nNuméro de téléphone : ' + encodeURIComponent(document.getElementById('phone').value) + ' \nMail : ' + encodeURIComponent(document.getElementById('mail').value) + ' \nEntreprise : ' + encodeURIComponent(document.getElementById('company').value) + '\n\nMessage : ' + encodeURIComponent(document.getElementById('message').value);
         // + '&mail=' + encodeURIComponent(document.getElementById('mail').value)
    xhr.open('POST', url, true);

    //Send the proper header information along with the request
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    xhr.onreadystatechange = function () {
        if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            // console.log(xhr.responseText);
            console.log('okkkk');
            document.getElementById('submit').classList.add('bloque');
            document.getElementById('reponse').classList.add('actif');

        }
    };
    xhr.send(params);

    // let xhr2 = new XMLHttpRequest(),
    //     url2 = 'php/mail2.php',
    //     params2 = 'mail=' + encodeURIComponent(document.getElementById('mail').value);
    
    // xhr2.open('POST', url2, true);

    // //Send the proper header information along with the request
    // xhr2.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    // xhr2.onreadystatechange = function () {
    //     if(xhr2.readyState === XMLHttpRequest.DONE && xhr2.status === 200) {
    //     }
    // };
    // xhr2.send(params2);
}