document.addEventListener("DOMContentLoaded", function() {

    //------------------------------//
    // APPELS AU PREMIER CHARGEMENT //
    //------------------------------//
    history.pushState({}, '', location);
    
    //requestion animation frame qui listen que les images soient chargées
    le_raf_chargement();

    if(!isMobile()){
        document.querySelector('body').classList.add('desktop');
    }else{
        document.querySelector('body').classList.add('mobile');
    }

    // s'il y a des images à charger
    if(document.querySelectorAll('.preload').length !== 0){
        
        var imgsProchainePage = [];

        for (var i = 0; i < document.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(document.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }
        
        chargementImages(imgsProchainePage);

    }else{
        sortie_images = true;
    }

    raf();



    // LISTE CUSTOM EASE
    CustomEase.create("QuartOut", "0.860, 0.000, 0.070, 1.000"); //0.26, 1.00, 0.48, 1.00
    CustomEase.create("QuartOutOld", "0.26, 1.00, 0.48, 1.00"); //0.26, 1.00, 0.48, 1.00
    CustomEase.create("CubicIn", "0.40, 0.00, 0.68, 0.06");


    // global
    let leCurrent, currentScroll = 0;
    document.getElementById('openMenu').addEventListener('click', function(){

        if(!animEnCours && !document.body.classList.contains('menuOpened')){
            leCurrent = document.querySelector('.liens .current');
            console.log(leCurrent);

            animEnCours = true;
            document.body.classList.add('menuOpened');
            TweenMax.to('#main', 0.4, {opacity:0, onComplete:function(){
                if(!isMobile()){
                    le_scroll.off();
                }else{
                    currentScroll = document.body.getBoundingClientRect().top;
                    document.body.classList.add('bloque');
                }
                TweenMax.to('#menu', 0.4, {opacity:1});
                TweenMax.staggerTo('.liens a', 1, {x:"0%", ease:Power2.easeOut}, 0.1, finAnimMenu);
                TweenMax.staggerTo('.liens a span', 1, {opacity:1, ease:Power2.easeOut}, 0.1);
                
                document.querySelector('#openMenu img').classList.add('activ');

                animEnCours = false;
            }});
        }else if(!animEnCours && document.body.classList.contains('menuOpened')){
            animEnCours = true;

            document.querySelector('#openMenu img').classList.remove('activ');

            document.body.classList.remove('bloque');
            window.scrollTo(0, Math.abs(currentScroll));

            TweenMax.to('#menu', 0.4, {opacity:0, onComplete:function(){
                document.body.classList.remove('menuOpened');
                if(!isMobile()){
                    le_scroll.on();
                }
                TweenMax.to('#main', 0.4, {opacity:1});
                TweenMax.set('.liens a', {x:"-20%"});
                TweenMax.set('.liens a span', {opacity:0});
                if(document.querySelector('.current2') !== null){
                    document.querySelector('.current2').classList.remove('current2');
                }
                animEnCours = false;
            }});
        }
    });

    if(!isMobile()){
        document.querySelectorAll('.liens a').forEach(x => {
            x.addEventListener('mouseenter', function(){
                document.querySelectorAll('.liens a').forEach(x => x.classList.remove('current', 'current2'));
                x.classList.add('current', 'current2');
            });
        });
        document.querySelector('.liens').addEventListener('mouseleave', function(){
            document.querySelectorAll('.liens a').forEach(x => x.classList.remove('current', 'current2'));
            leCurrent.classList.add('current', 'current2');
        });
    }
    
}); 

function finAnimMenu(){
    document.querySelector('.liens .current').classList.add('current2');
}


// on vérifie que l'anim de sortie est bonne, que la requete ajax est faite et que les images sont chargées
function le_raf_chargement() {

    raf_chargement = requestAnimationFrame(le_raf_chargement);
    // console.log('tamer');
    
    if(sortie_ok && ajax_ok && sortie_images){
    	if(premier_appel){
    		initPage();
    	}else{
        	updatePage(contenu_nouvelle_page);
        }
        cancelAnimationFrame(raf_chargement);
    
    }
}






let sortie_ok = true, ajax_ok = true, lien_en_cours = false, sortie_lottie = false, raf_loader, raf_chargement, sortie_images = false, links, premier_appel = true, contenu_nouvelle_page, le_raf, ancien_height = 0, animEnCours = false;
let W = window.innerWidth, H = window.innerHeight;



// fonction appellée à chaque lancement de page
let initPage = function() {

    sortie_ok = false;
	ajax_ok = false;
    sortie_images = false;

    lien_en_cours = false;

   	// on lance les animations d'entree
    animationsEntree();

    // maj
    // quand clique on lien
    links = document.querySelectorAll('a');
    for(var a = 0; a < links.length; a++){
        links[a].removeEventListener('click', onClick);
        links[a].addEventListener('click', onClick);
    }

    function onClick (event) {
        
        if( !event.target.classList.contains('externe') ){

            event.preventDefault();
            //temp
            event.stopPropagation();

            // lancement requete ajax qu'une seule fois
            if( lien_en_cours === false ){

                // alert();

                lien_en_cours = true;
                let href = this.getAttribute('href');

                history.pushState({}, '', href);
                loadPage(href);
                le_raf_chargement();

                return false;

            }else{ return false; }
        }
    }
    

},

// quand get() terminé
ajaxLoad = function(html) {
    contenu_nouvelle_page = html;
    ajax_ok = true;

    // code pour charger les images dès que ajax terminé
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    
    if(doc.querySelectorAll('.preload').length !== 0){

        var imgsProchainePage = [];
        
        // doc.querySelectorAll('.preload').forEach(x => {
        //     imgsProchainePage.push(x.getAttribute('data-img'));
        // });
        for (var i = 0; i < doc.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(doc.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }

        //
        // console.log(doc.querySelectorAll('.preload')[0].getAttribute('data-url'));
        // let temp = new Image();
        // temp.src = doc.querySelectorAll('.preload')[0].getAttribute('data-url');
        // console.log(temp.client);

        chargementImages(imgsProchainePage);

    }else{
    	sortie_images = true;
    }

},
// animations de sortie sorties
loadPage = function(href) {

    let xhr = new XMLHttpRequest(),
        method = "GET",
        url = href;

    xhr.open(method, url, true);
    xhr.onreadystatechange = function () {
        if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            ajaxLoad(xhr.responseText);
        }
    };
    xhr.send(); 

    animationsSortie();

},
// mise à jour des données de la page
updatePage = function(html) {

    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    let liste_class = doc.body.getAttribute('class');
    
    // maj titre de la page
    let temp = doc.querySelector('title').innerHTML.replace('&amp;', '&');
    document.title = temp;


    // maj class du body
    document.body.setAttribute('class', doc.body.getAttribute('class'));
    

    if(!isMobile()){
        document.body.classList.add('desktop');
    }else{
        document.body.classList.add('mobile');
    }

    // maj contenu #main
    // console.log(doc.getElementById('main').innerHTML);
    document.getElementById('main').innerHTML = doc.getElementById('main').innerHTML;
    
    // on lance la nouvelle page
    initPage();
};




// gestion bouton prev/next navigateur
window.onpopstate = function(e) {
    
    if(e.state !== null) {

        loadPage(location.href);
        le_raf_chargement();
    }
};


function raf() {
    
    le_raf = requestAnimationFrame(raf);

    if(document.getElementById('main').clientHeight != ancien_height && !isMobile() && le_scroll !== null ){
        // console.log('changement height vs section');
        le_scroll.resize();
        ancien_height = document.getElementById('main').clientHeight;
    }

    if(document.querySelector('#categories .current') !== null){
        TweenMax.to('#categories .current .cover', 1, {x:(posX*100/(1.1*window.innerWidth) - 138)/20 + "%", ease:Linear.easeNone});
    }


    // console.log(sortie_ok, ajax_ok, sortie_images);

}


let posX = 0, posY = 0;
document.addEventListener("mousemove", function(event){
    posX = event.pageX;
    posY = event.pageY;

});












// ENTREE
// ENTREE
let le_scroll = null;
function animationsEntree(){

    TweenMax.to('#main', 0.4, {opacity:1, delay:0.2, onComplete:function(){
        if(document.body.classList.contains('single-projets')){
            eventsSingle();
        }
    }});
    if(document.querySelector('.current2') !== null){
        document.querySelector('.current2').classList.remove('current2');
    }
    document.querySelector('.liens .current').classList.remove('current');
    if(document.body.classList.contains('home')){
        // alert('home');
        document.querySelectorAll('.liens a')[0].classList.add('current');
    }else if(document.body.classList.contains('page-template-archive') || document.body.classList.contains('archive') || document.body.classList.contains('single') ) {
        // alert('real');
        document.querySelectorAll('.liens a')[1].classList.add('current');
    }else if(document.body.classList.contains('page-template-about')){
        // alert('about');
        document.querySelectorAll('.liens a')[2].classList.add('current');
    }else{
        // alert('contac');
        document.querySelectorAll('.liens a')[3].classList.add('current');
    }

    
    window.scrollTo(0, 0);

    if(premier_appel){
        premier_appel = false;
    }

    if(!isMobile() && document.querySelector('.vs-section') !== null){

        if(!document.body.classList.contains('single-membres')){
            le_scroll = new global_custom2.default({
                preload: true,
                noscrollbar: true,
                native: false,
                section: document.querySelector('.vs-section'),
                divs: document.querySelectorAll('.vs-div')
                //ease: 0.08

            });

            le_scroll.vs.options.mouseMultiplier = 0.4;

            le_scroll.resize();
            le_scroll.init();
        }
    }

    if(document.body.classList.contains('archive')){
        eventsArchive();
    }

    if(document.getElementById('categories') !== null){
        hoverCat();
    }


}




// SORTIE
// SORTIE
function animationsSortie(){

    document.querySelector('#openMenu img').classList.remove('activ');

    if(!isMobile() && document.querySelector('.vs-section') !== null){
        le_scroll.vars.current = le_scroll.vars.target = 0;
        if(le_scroll !== null){le_scroll.destroy();}
        le_scroll = null;
    }

    if(document.querySelector('.menuOpened') === null){
        TweenMax.to('#main', 0.4, {opacity:0, onComplete:function(){
            sortie_ok = true;
        }});
    }else{
        TweenMax.set('#main', {opacity:0});
        animEnCours = true;
        
        TweenMax.to('#menu', 0.4, {opacity:0, onComplete:function(){
            document.body.classList.remove('menuOpened');
            TweenMax.set('.liens a', {x:"-20%"});
            TweenMax.set('.liens a span', {opacity:0});
            animEnCours = false;
            sortie_ok = true;
        }});
    }

    
    
}






function eventsSingle(){
    console.log('ii');

    if(document.querySelector('.leCarousel') !== null){
        
        var slider = document.querySelector('.main-carousel');
        var flkty = new Flickity( slider, {
            cellAlign: 'center',
            contain: true,
            pageDots: false,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            on: {
                ready: function() {
                    var totalCells = this.cells.length;
                    var selectedCell = this.selectedIndex+1;
                    document.querySelector('.currentPro').innerHTML = '01';
                    document.querySelector('.numPro').innerHTML = '0'+this.cells.length;
                },
                change: function( index ) { updateStatus(index+1); }
            }
        });

        document.getElementById('next-cell').addEventListener("click", nextCell, false);
        document.getElementById('prev-cell').addEventListener("click", prevCell, false);

    }

    function updateStatus(selectedCell) {
        document.querySelector('.currentPro').innerHTML = '0'+selectedCell;
    }
 
    function nextCell() { flkty.next(true); }
    function prevCell() { flkty.previous(true); }
    
}






function hoverCat(){
    document.querySelectorAll('#categories a').forEach(x => {
        if(!isMobile()){
            x.addEventListener('mouseenter', function(){
                x.classList.add('current');
            });

            x.addEventListener('mouseleave', function(){
                x.classList.remove('current');
            });
        }

        
    });
}



function eventsArchive(){
    
    let margeDebut = (document.querySelector('.unProjet2').clientWidth * document.querySelectorAll('.unProjet2').length)/3;
    let deplacement = margeDebut;
    let multiple = document.querySelectorAll('.unProjet2').length / 3;
    let currentItem = 0;
    document.querySelector('.numPro').innerHTML = '0' + multiple;

    if(window.innerWidth > 767){
        TweenMax.set('.lesProjets', {x: -margeDebut + 'px'});
        TweenMax.set('.progress div', {width: document.querySelector('.progress').clientWidth/multiple + 'px'});

        document.querySelector('.nextProj').addEventListener('click', function(){
            if(!animEnCours){
                animEnCours = true;
                deplacement = deplacement + document.querySelector('.unProjet2').clientWidth;
                
                if(currentItem+1 === multiple){
                    document.querySelector('.currentPro').innerHTML = '01';
                }else{
                    document.querySelector('.currentPro').innerHTML = '0' + (currentItem+2);
                }
                
                // TweenMax.to('.background', 0.4, {x:"15%", ease:Power2.easeIn, onComplete:function(){
                //     TweenMax.to('.background', 0.5, {x:"0%", ease:Power2.easeOut});
                // }});

                TweenMax.to('.lesProjets', 0.8, {x: -deplacement + 'px', ease:Power4.easeInOut, onComplete:function(){
                    currentItem++;
                    if(currentItem >= multiple){
                        TweenMax.set('.lesProjets', {x: -margeDebut + 'px'});
                        deplacement = margeDebut;
                        currentItem = 0;
                    }
                    animEnCours = false;
                }});
                if(currentItem < multiple-1){
                    TweenMax.to('.progress div', 1, {width: document.querySelector('.progress').clientWidth/multiple + document.querySelector('.progress').clientWidth/multiple*(currentItem+1) + 'px', ease:Power4.easeInOut});
                }else{
                    TweenMax.to('.progress div', 1, {width: document.querySelector('.progress').clientWidth/multiple + 'px', ease:Power4.easeInOut});
                }
            }
        });
    }else{
        let i = 0;
        document.querySelectorAll('.unProjet2').forEach(x => {
            if(i >= multiple){
                x.style.display = "none";
            }
            i++;
        });
    }
}





